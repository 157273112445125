<template>
  <v-container>
    <v-list subheader>
      <div class="text-right"><EmailClient /></div>
      <v-subheader>E-Mail an</v-subheader>

      <RowItem
        v-for="dl in dls"
        v-bind:key="dl.description"
        :align="dl.emails.length > 1 ? 'start' : 'center'"
        :title="dl.description.replace('E-Mail an', '').replace(' der', '')"
      >
        <Mailto :dl="dl.emails" :bcc="dl.bcc"
          >{{ dl.emails.join(', ') }}
        </Mailto>
      </RowItem>
    </v-list>
  </v-container>
</template>
<script>
import { saveChildRoute } from '@/router/helper';
import EmailClient from '@/components/settings/EmailClient';
import Mailto from '@/components/Mailto';
import RowItem from '@/components/RowItem';

export default {
  name: 'SchoolClassContact',
  components: {
    EmailClient,
    Mailto,
    RowItem,
  },
  props: {
    id: { type: String },
  },
  computed: {
    schoolClassId() {
      return Number.parseInt(this.id);
    },
  },
  data() {
    return {
      dls: {},
      loading: false,
    };
  },
  methods: {
    async getData() {
      if (this.schoolClassId) {
        this.loading = true;
        this.schoolClass = await this.apiGet({ resource: 'schoolclass/schoolclass', id: this.schoolClassId });
        this.dls = await this.apiGet({ resource: 'schoolclass/mail', id: this.schoolClassId });
        this.loading = false;
      }
    },
  },
  watch: {
    async id() {
      await this.getData();
    },
  },
  async created() {
    await this.getData();
  },
  beforeRouteUpdate(to, from, next) {
    saveChildRoute(to, next);
  },
};
</script>
